import React from "react";
import { graphql, Link } from "gatsby";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";
import Gallery from "../components/Gallery";

export default class ImageTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const post = data.airtable.data;
    const author = post.author ? post.author : "";

    if (!post.id) {
      post.id = slug;
    }
    console.log(data);
    console.log(post.author);
    console.log(author)
    const pics = post.image ? post.image.localFiles : null;
    console.log(pics)
    // return <div>imsagejsx</div>

    if ( pics != null) {
      return (
        <>
                <p> { author}   </p>
                <Gallery pics={pics} title="Gallery" />
        </>
      );
    } else {
        return <div>nothing</div>
    }
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    airtable(table: {eq: "images"}, , data: {slug: {eq: $slug}}) {
        data {
          slug
          title
          status
          date
          author
          image {
            id
            localFiles {
                childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
  }
`;